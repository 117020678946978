var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-card-title',[_c('span',{staticClass:"secondary--text title-hover",class:{
          'title-hoverable': _vm.hideOptions,
          'title-hover': _vm.hideOptions,
        },on:{"click":function($event){return _vm.$emit('productSelected', _vm.productLocal)}}},[_vm._v(_vm._s(_vm.productLocal.name))]),_c('v-spacer'),(!_vm.hideOptions)?_c('div',[(
            _vm.hasPermission(533) || _vm.hasPermission(535) || _vm.hasPermission(543)
          )?_c('product-menu',{on:{"updateProductInfo":function($event){_vm.showModificationDialog = true},"addStockToBranchOffice":function($event){_vm.showStockDialog = true},"deleteProduct":function($event){_vm.showDeletionDialog = true}}}):_vm._e()],1):_vm._e()],1),_c('v-card-subtitle',[_c('div',{staticClass:"py-1"},[_c('span',[_vm._v("SKU: "+_vm._s(_vm.productLocal.sku))])]),_c('v-chip',{attrs:{"label":""}},[_vm._v(_vm._s(_vm.currencyFormat(_vm.productLocal.unitCost)))]),_c('v-chip',{staticClass:"ml-2",attrs:{"label":"","color":"secondary lighten-2"}},[_vm._v(_vm._s(_vm.totalStock)+" piezas totales")])],1),_c('v-card-text',[(_vm.showStock)?_c('v-btn',{attrs:{"text":"","color":"secondary","small":""},on:{"click":function($event){_vm.showStock = false}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-chevron-down")]),_c('span',[_vm._v("Ocultar existencias")])],1):_c('v-btn',{attrs:{"text":"","color":"secondary","small":""},on:{"click":function($event){_vm.showStock = true}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-chevron-right")]),_c('span',[_vm._v("Ver existencias")])],1),(_vm.showStock)?_c('div',[(_vm.productLocal.stock.length)?_c('v-list',_vm._l((_vm.productLocal.stock),function(stockItem){return _c('v-list-item',{key:stockItem._id},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(stockItem.branchOffice.name))]),_c('v-list-item-subtitle',[_c('span',[_vm._v(_vm._s(stockItem.existences)+" existencias")])])],1),(_vm.canUpdateToBranchOffice(stockItem.branchOffice._id))?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.openExistencesDialog(stockItem)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Cambiar existencias")])])],1):_vm._e()],1)}),1):_c('span',[_vm._v("Sin stock en ninguna sucursal")])],1):_vm._e()],1)],1),(_vm.showModificationDialog)?_c('product-modification',{attrs:{"show":_vm.showModificationDialog,"currentProduct":_vm.productLocal},on:{"cancel":function($event){_vm.showModificationDialog = false},"productSaved":_vm.productSaved}}):_vm._e(),(_vm.showStockDialog)?_c('stock-adder',{attrs:{"show":_vm.showStockDialog,"product":_vm.productLocal},on:{"cancel":function($event){_vm.showStockDialog = false},"productSaved":_vm.productSaved}}):_vm._e(),(_vm.showExistencesDialog)?_c('existences-changer',{attrs:{"show":_vm.showExistencesDialog,"productId":_vm.productLocal._id,"stockItem":_vm.stockItemSelected},on:{"cancel":function($event){return _vm.closeExistencesDialog()}}}):_vm._e(),(_vm.showDeletionDialog)?_c('product-deletion',{attrs:{"show":_vm.showDeletionDialog,"productId":_vm.productLocal._id},on:{"cancel":function($event){_vm.showDeletionDialog = false},"productDeleted":function($event){return _vm.$emit('productDeleted', _vm.productLocal)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }